/**
 * @author AUTHOR_NAME
 * @email AUTHOR_EMAIL
 * @create date
 * @modify date
 * @desc All global CSS are defined here
 */

::-webkit-scrollbar {
  width: 6px !important;
}

/* this targets the default scrollbar (compulsory) */

/* Track */
::-webkit-scrollbar-track {
  background: transparent !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.23) !important;
  border-radius: 24px;
  transition: all 0.5s;
  cursor: pointer;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(146, 151, 165, 1) !important;
}

/* this will style the thumb, ignoring the track */

::-webkit-scrollbar-button {
  background-color: transparent !important;
}

/* optionally, you can style the top and the bottom buttons (left and right for horizontal bars) */

::-webkit-scrollbar-corner {
  background-color: transparent !important;
}

/* if both the vertical and the horizontal bars appear, then perhaps the right bottom corner also needs to be styled */
*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0px
}

html {
  height: 100vh;
}

#root {
  height: 100%;
}

body {
  scrollbar-face-color: #f1f1f1 !important;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

@font-face {
  font-family: 'IPMO_bold';
  /* src        : url('./font/Poppins-Bold.ttf'); */
  src: local("AssistantRegular"), url('./font/Poppins-Bold.ttf') format("truetype");
  font-display: swap;
}

@font-face {
  font-family: 'IPMO_medium';
  /* src        : url('./font/Poppins-Medium.ttf'); */
  src: local("AssistantRegular"), url('./font/Poppins-Medium.ttf') format("truetype");
  font-display: swap;
}

@font-face {
  font-family: 'IPMO_regular';
  /* src        : url('./font/Poppins-Regular.ttf'); */
  src: local("AssistantRegular"), url('./font/Poppins-Regular.ttf') format("truetype");
  font-display: swap;
}

.MuiPickersDateRangePickerInput-root {
  width: 100%;
}

/* use this class to attach this font to any element i.e. <p class="fontsforweb_fontid_9785">Text with this font applied</p> */
body {
  font-family: 'IPMO_regular' !important;
}

code {
  font-family: 'IPMO_regular' !important;
}

textarea:focus,
input:focus {
  font-family: 'IPMO_regular' !important;
}

input,
select,
textarea {
  font-family: 'IPMO_regular' !important;
}

:fullscreen {
  z-index: 5;
}